
/* Media query for mobile view */
@media screen and (max-width: 768px) {
    /* Toggle menu visibility */
    .dms-menu.active {
        display: flex;
        z-index: 999;
    }
    .dms-menu.active a {
        color: #EDE9DD;
    }
}